import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const { checkRequired, errors, clearErrors, checkRequiredOption } = validator();

export function validateCreatedQueueAction(data) {
  clearErrors();

  data = unref(data);

  checkRequired("name", data);
  checkRequiredOption("action", data);
  checkRequiredOption("value", data);

  return hasErrors.value ? false : true;
}

export function validateCreatedQueueRule(data) {
  clearErrors();

  data = unref(data);

  checkRequired("name", data);
  checkRequired("increase_weight", data);
  checkRequiredOption("timezone_id", data);
  checkRequiredOption("period", data);

  return hasErrors.value ? false : true;
}

export function validateCreatedQueueMember(data) {
  clearErrors();

  data = unref(data);

  checkRequired("workplace", data);
  checkRequired("auto_answer", data);
  checkRequired("skill_level", data);

  return hasErrors.value ? false : true;
}

export function validateUpdateQueueMember(data) {
  clearErrors();

  data = unref(data);

  checkRequired("workplace", data);

  return hasErrors.value ? false : true;
}

export function validateCreatedQueueBlacklist(data) {
  clearErrors();

  data = unref(data);

  checkRequired("calling_number", data);
  checkRequired("valid_to", data);

  return hasErrors.value ? false : true;
}

export function validateCreatedQueueVip(data) {
  clearErrors();

  data = unref(data);

  checkRequired("calling_number", data);
  checkRequired("weight", data);

  return hasErrors.value ? false : true;
}

export function validateCreatedRingGroupMember(data) {
  clearErrors();

  data = unref(data);

  let ringTime = data;

  if (data.ring_time?.name) ringTime = data.ring_time;

  checkRequired("name", data);
  checkRequired("number", data);
  checkRequired("ring_time", ringTime);

  return hasErrors.value ? false : true;
}
