<template>
  <div class="tabs-container">
    <TabView>
      <TabPanel
        v-for="(tab, index) in tabs"
        :key="index"
        :header="transformHeaders(tab)"
      >
        <div>
          <QueueGeneral
            :data="data[tab]"
            v-if="tab === 'general' || tab === 'queue-general'"
          />
          <QueueRules :data="data[tab]" v-if="tab === 'queue-rules'" />
          <TimeoutActions
            :data="data[tab]"
            v-if="tab === 'timeout-actions' || tab === 'queue-actions'"
          />
          <QueueMembers :data="data[tab]" v-if="tab === 'queue-members'" />
          <BlacklistNumbers :data="data[tab]" v-if="tab === 'queue-bnumbers'" />
          <VipNumbers :data="data[tab]" v-if="tab === 'queue-vnumbers'" />
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, inject } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import QueueGeneral from "@/modules/xpbx/pages/settings/queue-detail/components/QueueGeneral/QueueGeneral.vue";
import QueueRules from "@/modules/xpbx/pages/settings/queue-detail/components/QueueRules/QueueRules.vue";
import TimeoutActions from "@/modules/xpbx/pages/settings/queue-detail/components/QueueActions/QueueActions.vue";
import QueueMembers from "@/modules/xpbx/pages/settings/queue-detail/components/QueueMembers/QueueMembers.vue";
import BlacklistNumbers from "@/modules/xpbx/pages/settings/queue-detail/components/BlacklistNumbers/BlacklistNumbers.vue";
import VipNumbers from "@/modules/xpbx/pages/settings/queue-detail/components/QueueVipNumbers/QueueVipNumbers.vue";

export default {
  name: "QueueTabs",

  components: {
    TabView,
    TabPanel,
    QueueGeneral,
    QueueRules,
    TimeoutActions,
    VipNumbers,
    QueueMembers,
    BlacklistNumbers,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const t = inject("t");
    const store = useStore();
    const queueData = computed(() => store.state.xadmin.queue);

    const tabs = computed(() => {
      if (!queueData.value?.["queue-general"]) return [];
      const tabsData = [];

      const objectKeys = {
        "queue-general": "General",
        "queue-members": "Members",
        "queue-actions": "Actions",
        "queue-rules": "Rules",
        "queue-vnumbers": "VIP Numbers",
        "queue-bnumbers": "Blacklist Numbers",
      };
      for (const key in objectKeys) {
        tabsData.push(key);
      }

      return tabsData;
    });

    const transformHeaders = (value) => {
      const title = t(`xpbx.settings.queues.tabs.${value}`);

      return title ? title : value;
    };

    return {
      tabs,
      queueData,
      transformHeaders,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>