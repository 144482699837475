<template>
  <div>
    <div
      class="card relative table-wrapper"
      :class="{ 'table-none': !data?.length }"
    >
      <DataTable
        ref="dt"
        scrollable
        :value="data"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        selectionMode="single"
        v-model:selection="selectedRecords"
      >
        <template #empty>
          {{ $t("xpbx.settings.queue-rules.fields.empty_table") }}</template
        >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                class="mr-2 add-record-button"
                @click="openNew"
                :label="$t('xpbx.settings.queue-rules.buttons.new')"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column
          sortable
          style="width: 25%"
          field="name"
          :header="$t('xpbx.settings.queue-rules.fields.name')"
        >
          <template #body="{ data }">
            <span>{{ data.name }}</span>
            <TableActions
              :data="data"
              :id="data.id"
              @edit="editHandle"
              :showIsActive="false"
              @delete="deleteSelectedRecord"
            />
          </template>
        </Column>
        <Column
          sortable
          style="width: 25%"
          field="increase_weight"
          :header="$t('xpbx.settings.queue-rules.fields.increase_weight')"
        >
        </Column>
        <Column
          sortable
          style="width: 25%"
          field="period"
          :header="$t('xpbx.settings.queue-rules.fields.period')"
        ></Column>
        <Column
          sortable
          style="width: 25%"
          field="timezone"
          :header="$t('xpbx.settings.queue-rules.fields.timezone')"
        >
          <template #body="{ data }">
            {{
              data?.timezone ? data.timezone : findTimezone(data.timezone_id)
            }}
          </template>
        </Column>
      </DataTable>

      <!-- <Loader v-else /> -->
      <p class="text-center my-2" v-if="!data?.length">
        {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}
      </p>
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.name">
          {{
            $t("xpbx.settings.queue-rules.notification.confirm_delete", {
              delete: `domain ${selectedRecord?.name}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          text
          @click="deleteDialog = false"
          :label="$t('xpbx.settings.queue-rules.buttons.delete')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.settings.queue-rules.buttons.delete')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.queue-rules.notification.edit_queue_rule')
          : $t('xpbx.settings.queue-rules.notification.create_queue_rule')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!--Name -->
      <FormInput
        id="name"
        :error="errors?.name?.[0]"
        v-model:modelValue="record.name"
        :label="$t('xpbx.settings.queue-rules.labels.name')"
        :hint="$t('xpbx.settings.queue-rules.hints.name')"
      />

      <!-- Timezone -->
      <FormDropdown
        optionLabel="name"
        :options="timezonesData"
        :error="errors?.timezone_id?.[0]"
        v-model:modelValue="record.timezone_id"
        :placeholder="$t('xpbx.settings.queue-rules.placeholders.timezone')"
        :label="$t('xpbx.settings.queue-rules.labels.timezone')"
        :hint="$t('xpbx.settings.queue-rules.hints.timezone')"
      />

      <!-- Period -->
      <FormDropdown
        optionLabel="name"
        :options="agentPeriodOptions"
        :error="errors?.period?.[0]"
        v-model:modelValue="record.period"
        :placeholder="$t('xpbx.settings.queue-rules.placeholders.period')"
        :label="$t('xpbx.settings.queue-rules.labels.period')"
        :hint="$t('xpbx.settings.queue-rules.hints.period')"
      />

      <!-- Weigth -->
      <FormDropdown
        :options="wieghtOptionsV2"
        :error="errors?.increase_weight?.[0]"
        v-model:modelValue="record.increase_weight"
        :placeholder="$t('xpbx.settings.queue-rules.placeholders.weight')"
        :label="$t('xpbx.settings.queue-rules.labels.weight')"
        :hint="$t('xpbx.settings.queue-rules.hints.increase_weight')"
      />

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="createDialog = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed, inject } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import useTimezones from "@/modules/xpbx/composables/useTimezones";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import { validateCreatedQueueRule } from "@/composables/auth/queueValidatuions";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "QueueRules",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    FormInput,
    FormDropdown,
    InputSwitch,
    Loader,
    TableActions,
    DialogButtons,
  },

  props: {
    data: {
      type: Object || Array,
      required: true,
    },
  },

  setup() {
    const t = inject("t");
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const record = ref({});
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const selectText = t("xpbx.settings.dropdown.select_option");

    const { timezones, findTimezones } = useTimezones();
    const { updateQueueOption, createQueueOption, deleteQueueOption } =
      useQueues();
    const { wieghtOptionsV2, agentPeriodOptions, getOptionValue } =
      useQueueOptions();

    const timezonesData = computed(() => {
      if (!timezones.value) return [];

      return timezones.value.map((item) => {
        return {
          name: item.description,
          value: item.id,
        };
      });
    });

    const setDefaultRecord = () => {
      record.value = {
        name: "",
        timezone_id: {
          name: selectText,
          value: null,
        },
        period: {
          name: selectText,
          value: null,
        },
        increase_weight: "1",
      };
    };

    // Data table functions
    const openNew = () => {
      setDefaultRecord();
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      setDefaultRecord();
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;
      await deleteQueueOption(
        selectedRecord.value,
        id,
        selectedRecord.value.id,
        "rules"
      );
      deleteDialog.value = false;
    };

    const editHandle = (data) => {
      const selectedTimezone = timezonesData.value.find(
        (item) => item.value === data.timezone_id
      );

      const form = {
        id: data.id,
        name: data.name,
        timezone_id: selectedTimezone
          ? selectedTimezone
          : {
              name: selectText,
              value: null,
            },
        period: {
          name: `${data.period}`,
          value: data.period,
        },
        increase_weight: `${data.increase_weight}`,
      };

      record.value = form;
      isEdit.value = true;
      selectedRecord.value = data;
      createDialog.value = true;
    };

    const updateRecord = async (formData) => {
      const id = route.params.id;

      if (formData.id) {
        await updateQueueOption(formData, id, formData?.id, "rules");
      }
      createDialog.value = false;
    };

    const createRecord = async (formData) => {
      const id = route.params.id;
      await createQueueOption(formData, id, "rules");
      createDialog.value = false;
    };

    const create = async () => {
      const isValid = validateCreatedQueueRule(record.value);

      if (!isValid) return;

      const period = getOptionValue(record.value.period);
      const weight = getOptionValue(record.value.increase_weight);

      const formData = {
        name: record.value.name,
        timezone_id: getOptionValue(record.value.timezone_id),
        period: period || period === 0 ? +period : null,
        increase_weight: weight ? +weight : null,
      };
      submitted.value = true;

      if (isEdit.value) {
        formData.id = record.value.id;
        await updateRecord(formData);
      } else {
        await createRecord(formData);
      }
    };

    const findTimezone = (id) => {
      const timezone = timezones.value.find((item) => item.id === id);
      return timezone ? timezone.description : "";
    };

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findTimezones();
    });

    return {
      record,
      isEdit,
      errors,
      submitted,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      selectedRecords,
      timezones,
      timezonesData,
      wieghtOptionsV2,
      agentPeriodOptions,
      // Data table functions
      reset,
      create,
      openNew,
      editHandle,
      closeDialog,
      findTimezone,
      findTimezones,
      createRecord,
      updateRecord,
      setDefaultRecord,
      createQueueOption,
      updateQueueOption,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>