<template>
  <div>
    <div
      class="card relative table-wrapper"
      :class="{ 'table-none': !data?.length }"
    >
      <DataTable
        ref="dt"
        scrollable
        :value="data"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        dataKey="id"
        selectionMode="single"
        v-model:selection="selectedRecords"
      >
        <template #empty>
          {{ $t("xpbx.settings.queue-vnumbers.fields.empty_table") }}</template
        >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                class="mr-2 add-record-button"
                @click="openNew"
                :label="$t('xpbx.settings.queue-vnumbers.buttons.new')"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column
          sortable
          style="width: 50%"
          field="calling_number"
          :header="$t('xpbx.settings.queue-vnumbers.fields.calling_number')"
        >
          <template #body="{ data }">
            {{ data?.calling_number }}
            <TableActions
              :data="data"
              :id="data.id"
              @edit="editHandle"
              @setActive="setActive"
              :showIsActive="true"
              :isActive="data.is_active"
              @delete="deleteSelectedRecord"
            />
            <!-- <div>
              <i
                v-tooltip.top="$t('xpbx.settings.tooltips.is_active')"
                class="fa-light fa-thumbs-up edit-icon clone-icon"
                :class="{ inactive: data?.is_active === 0 }"
                @click="setActive(data)"
              ></i>
            </div> -->
          </template>
        </Column>

        <Column
          sortable
          style="width: 50%"
          field="weight"
          :header="$t('xpbx.settings.queue-vnumbers.fields.weight')"
        ></Column>
      </DataTable>

      <p class="text-center my-2" v-if="!data?.length">
        {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}
      </p>
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.calling_number">
          {{
            $t("xpbx.settings.queue-vnumbers.notification.confirm_delete", {
              delete: `number ${selectedRecord?.calling_number}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          text
          @click="deleteDialog = false"
          :label="$t('xpbx.settings.queue-vnumbers.buttons.no')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.settings.queue-vnumbers.buttons.yes')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.queue-vnumbers.dialogs.edit_vip_number')
          : $t('xpbx.settings.queue-vnumbers.dialogs.create_vip_number')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!--Name -->
      <FormInput
        id="calling_number"
        :error="errors?.calling_number?.[0]"
        v-model:modelValue="record.calling_number"
        :label="$t('xpbx.settings.queue-vnumbers.labels.number')"
        :hint="$t('xpbx.settings.queue-vnumbers.hints.calling_number')"
      />

      <!-- Weight -->
      <FormInput
        id="weight"
        :error="errors?.weight?.[0]"
        v-model:modelValue="record.weight"
        :label="$t('xpbx.settings.queue-vnumbers.labels.weight')"
        :hint="$t('xpbx.settings.queue-vnumbers.hints.weight')"
      />

      <!-- Is active -->
      <InputSwitch
        v-model:modelValue="record.is_active"
        :label="$t('xpbx.settings.queue-vnumbers.labels.is_active')"
      />
      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="createDialog = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Tooltip from "primevue/tooltip";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import { validateCreatedQueueVip } from "@/composables/auth/queueValidatuions";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "QueueVipNumbers",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    FormInput,
    TableActions,
    InputSwitch,
    DialogButtons,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    data: {
      type: Object || Array,
      required: true,
    },
  },

  setup() {
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const record = ref({});
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);

    const { updateQueueOption, createQueueOption, deleteQueueOption } =
      useQueues();

    // Data table functions
    const openNew = () => {
      record.value = {
        calling_number: "",
        weight: "",
        is_active: false,
      };
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      record.value = {
        calling_number: "",
        weight: "",
        is_active: false,
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;

      if (selectedRecord.value.id) {
        await deleteQueueOption(
          selectedRecord.value,
          id,
          selectedRecord.value.id,
          "vip-numbers"
        );
      }
      deleteDialog.value = false;
    };

    const editHandle = (data) => {
      const form = {
        ...data,
        is_active: data.is_active === 1 ? true : false,
      };
      record.value = form;
      isEdit.value = true;
      selectedRecord.value = data;
      createDialog.value = true;
    };

    const updateRecord = async (data) => {
      const id = route.params.id;
      const formData = {
        ...data,
        is_active: data.is_active ? 1 : 0,
      };

      if (formData.id)
        await updateQueueOption(formData, id, formData.id, "vip-numbers");
      createDialog.value = false;
    };

    const createRecord = async (data) => {
      const isValid = validateCreatedQueueVip(data);
      if (isValid) {
        const id = route.params.id;
        const formData = {
          ...data,
          is_active: data.is_active ? 1 : 0,
        };

        await createQueueOption(formData, id, "vip-numbers");
        createDialog.value = false;
      }
    };

    const create = async () => {
      submitted.value = true;
      if (isEdit.value) {
        await updateRecord(record.value);
      } else {
        await createRecord(record.value);
      }
    };

    const setActive = async (data) => {
      const id = route.params.id;
      const formData = {
        ...data,
        is_active: data.is_active === 1 ? 0 : 1,
      };

      await updateQueueOption(formData, id, data.id, "vip-numbers");
    };

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    return {
      record,
      isEdit,
      errors,
      submitted,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      selectedRecords,
      // Data table functions
      reset,
      create,
      openNew,
      setActive,
      editHandle,
      closeDialog,
      createRecord,
      updateRecord,
      createQueueOption,
      updateQueueOption,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>
