<template>
  <div>
    <div
      class="card relative table-wrapper"
      :class="{ 'table-none': !data?.length }"
    >
      <DataTable
        ref="dt"
        scrollable
        :value="data"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        dataKey="id"
        selectionMode="single"
        v-model:selection="selectedRecords"
      >
        <template #empty>
          {{ $t("xpbx.settings.queue-bnumbers.fields.empty_table") }}</template
        >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                @click="openNew"
                class="mr-2 add-record-button"
                :label="$t('xpbx.settings.queue-bnumbers.buttons.new')"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column
          sortable
          style="width: 50%"
          field="calling_number"
          :header="$t('xpbx.settings.queue-bnumbers.fields.calling_number')"
        >
          <template #body="{ data }">
            {{ data?.calling_number }}
            <TableActions
              :data="data"
              :id="data.id"
              @edit="editHandle"
              @setActive="setActive"
              :showIsActive="true"
              :isActive="data.is_active"
              @delete="deleteSelectedRecord"
            />
          </template>
        </Column>
        <Column
          sortable
          style="width: 50%"
          field="valid_to"
          :header="$t('xpbx.settings.queue-bnumbers.fields.valid_to')"
        >
          <template #body="{ data }">
            <DateItem :value="data?.valid_to" /> </template
        ></Column>
      </DataTable>

      <p class="text-center my-2" v-if="!data?.length">
        {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}
      </p>
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.calling_number">
          {{
            $t("xpbx.settings.queue-bnumbers.notification.confirm_delete", {
              delete: `queue black number ${selectedRecord?.calling_number}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          text
          @click="deleteDialog = false"
          :label="$t('xpbx.settings.queue-bnumbers.buttons.no')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.settings.queue-bnumbers.buttons.yes')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.queue-bnumbers.dialogs.edit_blacklist_numbers')
          : $t('xpbx.settings.queue-bnumbers.dialogs.create_blacklist_numbers')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!--Number -->
      <FormInput
        id="calling_number"
        :error="errors?.calling_number?.[0]"
        v-model:modelValue="record.calling_number"
        :label="$t('xpbx.settings.queue-bnumbers.labels.number')"
        :hint="$t('xpbx.settings.queue-bnumbers.hints.calling_number')"
      />

      <!-- Valid to -->
      <div class="field mb-2">
        <label for="value">{{
          $t("xpbx.settings.queue-bnumbers.labels.valid_to")
        }}</label>
        <div class="w-40">
          <Calendar v-model="record.valid_to" />
        </div>
        <small class="p-error" v-if="!!errors?.valid_to?.[0]">{{
          errors?.valid_to?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.settings.queue-bnumbers.hints.date')" />

      <!-- Is active -->
      <InputSwitch
        v-model:modelValue="record.is_active"
        :label="$t('xpbx.settings.queue-bnumbers.labels.is_active')"
      />

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="createDialog = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Tooltip from "primevue/tooltip";
import Calendar from "primevue/calendar";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import { validateCreatedQueueBlacklist } from "@/composables/auth/queueValidatuions";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "BlacklistNumbers",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    FormInput,
    InputSwitch,
    DateItem,
    Calendar,
    QueueHint,
    TableActions,
    DialogButtons,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    data: {
      type: Object || Array,
      required: true,
    },
  },

  setup() {
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const record = ref({});
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);

    const { updateQueueOption, createQueueOption, deleteQueueOption } =
      useQueues();

    // Data table functions
    const openNew = () => {
      record.value = {
        calling_number: "",
        valid_to: "",
        is_active: false,
      };
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      record.value = {
        calling_number: "",
        valid_to: "",
        is_active: false,
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      confirmDeleteSelected();
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;

      if (selectedRecord.value.id) {
        await deleteQueueOption(
          selectedRecord.value,
          id,
          selectedRecord.value.id,
          "black-numbers"
        );
      }
      deleteDialog.value = false;
    };

    const editHandle = (data) => {
      const form = {
        ...data,
        is_active: data.is_active === 1 ? true : false,
      };
      record.value = form;
      isEdit.value = true;
      selectedRecord.value = data;
      createDialog.value = true;
    };

    const updateRecord = async (data) => {
      const id = route.params.id;
      const date = new Date(data.valid_to).toISOString().split("T")[0];
      const formData = {
        ...data,
        is_active: data.is_active ? 1 : 0,
        valid_to: date,
      };

      await updateQueueOption(formData, id, formData.id, "black-numbers");
      createDialog.value = false;
    };

    const createRecord = async (data) => {
      const isValid = validateCreatedQueueBlacklist(data);
      if (isValid) {
        const id = route.params.id;
        const date = new Date(data.valid_to).toISOString().split("T")[0];
        const formData = {
          ...data,
          is_active: data.is_active ? 1 : 0,
          valid_to: date,
        };

        await createQueueOption(formData, id, "black-numbers");
        createDialog.value = false;
      }
    };

    const create = async () => {
      // const isValid = validateDomain(record.value);
      submitted.value = true;
      if (isEdit.value) {
        await updateRecord(record.value);
      } else {
        await createRecord(record.value);
      }
    };

    const setActive = async (data) => {
      const id = route.params.id;
      const formData = {
        ...data,
        is_active: data.is_active === 1 ? 0 : 1,
      };

      await updateQueueOption(formData, id, data.id, "black-numbers");
    };

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    return {
      record,
      isEdit,
      errors,
      submitted,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      selectedRecords,
      // Data table functions
      reset,
      create,
      openNew,
      setActive,
      editHandle,
      closeDialog,
      createRecord,
      updateRecord,
      createQueueOption,
      updateQueueOption,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>