<template>
  <div class="queue-general-wrapper scrollable-wrapper">
    <div
      class="relative mt-4 flex flex-wrap justify-between gap-4 overflow-y-auto"
    >
      <!--Name -->
      <div class="w-2/5">
        <FormInput
          id="name"
          :error="errors?.name?.[0]"
          v-model:modelValue="form.name"
          :label="$t('xpbx.settings.queue-general.labels.name')"
          :hint="$t('xpbx.settings.queue-general.hints.name')"
        />
      </div>

      <!--Strategy -->
      <div class="w-2/5">
        <FormDropdown
          optionLabel="name"
          :options="strategyOptions"
          :error="errors?.strategy?.[0]"
          v-model:modelValue="form.strategy"
          :label="$t('xpbx.settings.queue-general.labels.strategy')"
          :hint="$t('xpbx.settings.queue-general.hints.strategy')"
        />
      </div>

      <!--Queue number -->
      <div class="w-2/5">
        <FormInput
          id="queue_number"
          :error="errors?.queue_number?.[0]"
          v-model:modelValue="form.queue_number"
          :label="$t('xpbx.settings.queue-general.labels.queue_number')"
          :hint="$t('xpbx.settings.queue-general.hints.queue_number')"
        />
      </div>

      <!--Wrapuptime -->
      <div class="w-2/5">
        <FormDropdown
          :options="wrapOptions"
          :error="errors?.wrapuptime?.[0]"
          v-model:modelValue="form.wrapuptime"
          :label="$t('xpbx.settings.queue-general.labels.wrapuptime')"
          :hint="$t('xpbx.settings.queue-general.hints.wrapuptime')"
        />
      </div>

      <!--Agent period -->
      <div class="w-2/5">
        <FormDropdown
          :options="wrapOptions"
          :error="errors?.find_agent_period?.[0]"
          v-model:modelValue="form.find_agent_period"
          :label="$t('xpbx.settings.queue-general.labels.find_agent_period')"
          :hint="$t('xpbx.settings.queue-general.hints.agent_period')"
        />
      </div>
      <!--Timeout -->
      <div class="w-2/5">
        <FormDropdown
          :options="timeoutSelect"
          :error="errors?.timeout?.[0]"
          v-model:modelValue="form.timeout"
          :label="$t('xpbx.settings.queue-general.labels.timeout')"
          :hint="$t('xpbx.settings.queue-general.hints.timeout')"
        />
      </div>

      <!--Weight -->
      <div class="w-2/5">
        <FormDropdown
          :options="wieghtOptionsV2"
          :error="errors?.weight?.[0]"
          v-model:modelValue="form.weight"
          :label="$t('xpbx.settings.queue-general.labels.weight')"
          :hint="$t('xpbx.settings.queue-general.hints.weight')"
        />
      </div>

      <!-- Right container -->
      <!-- Unblock -->
      <div class="w-2/5">
        <InputSwitch
          v-model:modelValue="form.unblock"
          :label="$t('xpbx.settings.queue-general.labels.unblock')"
          :hint="$t('xpbx.settings.queue-general.hints.unblock')"
        />
      </div>

      <!-- Connect again now -->
      <div class="w-2/5">
        <InputSwitch
          v-model:modelValue="form.connect_agent_now"
          :label="$t('xpbx.settings.queue-general.labels.connect_agent_now')"
          :hint="$t('xpbx.settings.queue-general.hints.connect_again')"
        />
      </div>
      <div class="w-2/5">
        <InputSwitch
          v-model:modelValue="form.is_active"
          :label="$t('xpbx.settings.queue-general.labels.is_active')"
        />
      </div>
    </div>
    <Divider />
    <div class="flex justify-start mt-5">
      <Button
        icon="pi pi-plus"
        severity="success"
        class="mr-2 add-record-button"
        @click="edit"
        :label="$t('xpbx.settings.queue-general.buttons.save')"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import Button from "primevue/button";
import Divider from "primevue/divider";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import { validateCreateQueue } from "@/composables/auth/createDomainValidations";

export default {
  name: "QueueGeneral",

  components: {
    Button,
    Divider,
    InputSwitch,
    FormInput,
    FormDropdown,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute();
    const submitted = ref(false);
    const { errors, clearErrors } = validator();
    const { updateQueueGeneral } = useQueues();
    const {
      activeOptions,
      weightOptions,
      wieghtOptionsV2,
      timeoutOptions,
      timeoutSelect,
      wrapOptions,
      wrapuptimeOptions,
      periodOptions,
      agentPeriodOptions,
      strategyOptions,
    } = useQueueOptions();

    const findStrategy = (strategy) => {
      return strategyOptions.find((option) => option.value === strategy);
    };

    const form = ref({
      ...props.data,
      weight: props.data?.weight ? `${props.data.weight}` : "1",
      timeout: props.data?.timeout ? `${props.data.timeout}` : "0",
      wrapuptime: props.data?.wrapuptime ? `${props.data.wrapuptime}` : "0",
      find_agent_period: props.data?.find_agent_period
        ? `${props.data.find_agent_period}`
        : "0",
      strategy: props.data?.strategy
        ? findStrategy(props.data.strategy)
        : {
            name: "Ringall",
            value: "ringall",
          },
      unblock: props.data?.unblock === 1 ? true : false,
      is_active: props.data?.is_active === 1 ? true : false,
      connect_agent_now: props.data?.connect_agent_now === 1 ? true : false,
    });

    const edit = async () => {
      submitted.value = true;

      // Call the API to update the queue
      const formData = {
        ...form.value,
        strategy: form.value?.strategy?.value
          ? form.value.strategy.value
          : form.value.strategy || "",
        unblock: form.value.unblock ? 1 : 0,
        is_active: form.value.is_active ? 1 : 0,
        connect_agent_now: form.value.connect_agent_now ? 1 : 0,
      };

      const isValid = validateCreateQueue(formData);

      console.log("isValid", isValid, errors.value);

      if (!isValid) {
        return;
      }

      await updateQueueGeneral(formData, route.params.id);
    };

    return {
      form,
      errors,
      submitted,
      weightOptions,
      wieghtOptionsV2,
      activeOptions,
      timeoutOptions,
      timeoutSelect,
      strategyOptions,
      wrapOptions,
      wrapuptimeOptions,
      periodOptions,
      agentPeriodOptions,

      edit,
      clearErrors,
      findStrategy,
      updateQueueGeneral,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}

.queue-general-wrapper {
  max-height: 70vh;
  overflow-y: auto;
}

@media (min-height: 900px) {
  .queue-general-wrapper {
    max-height: 80vh;
    overflow-y: auto;
  }
}
</style>
