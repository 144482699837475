<template>
  <div>
    <div
      class="card relative table-wrapper"
      :class="{ 'table-none': !data?.length }"
    >
      <DataTable
        ref="dt"
        scrollable
        :value="data"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        paginatorPosition="top"
        paginator
        :rows="10"
        :rowsPerPageOptions="[10, 20, 50]"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        selectionMode="single"
        v-model:selection="selectedRecords"
      >
        <template #empty>
          {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}</template
        >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                class="mr-2 add-record-button"
                @click="openNew"
                :label="$t('xpbx.settings.queue-actions.buttons.new')"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column
          sortable
          style="width: 25%"
          field="name"
          :header="$t('xpbx.settings.queue-actions.fields.name')"
        >
          <template #body="{ data }">
            {{ data?.name }}
            <TableActions
              :data="data"
              :id="data.id"
              @edit="editHandle"
              @setActive="setActive"
              :showIsActive="true"
              :isActive="data.is_active"
              @delete="deleteSelectedRecord"
            />
          </template>
        </Column>
        <Column
          sortable
          style="width: 25%"
          field="timerange"
          :header="$t('xpbx.settings.queue-actions.fields.timerange')"
        >
          <template #body="{ data }">
            {{ data?.timerange?.[0] }} -
            {{ data?.timerange?.[1] }}
          </template>
        </Column>
        <Column
          sortable
          style="width: 25%"
          field="value"
          :header="$t('xpbx.settings.queue-actions.fields.value')"
        >
          <template #body="{ data }">
            {{ data.audio_description }}
            <div class="flex gap-3">
              <AudioPlayer
                :id="data.announcement_id"
                v-if="data?.announcement_id"
              />
              {{ getFilename(data?.announcement_id) }}
            </div>
          </template>
        </Column>
        <Column
          sortable
          style="width: 25%"
          field="action"
          :header="$t('xpbx.settings.queue-actions.fields.action')"
        >
          <template #body="{ data }">
            {{ getFileType(data?.action) }}
          </template>
        </Column>
      </DataTable>
      <p class="text-center my-2" v-if="!data?.length">
        {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}
      </p>
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.id">
          {{
            $t("xpbx.settings.queue-actions.notification.confirm_delete", {
              delete: `action ${selectedRecord?.name}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          text
          @click="deleteDialog = false"
          :label="$t('xpbx.settings.queue-actions.buttons.no')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.settings.queue-actions.buttons.yes')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.queue-actions.notification.edit_timeout_actions')
          : $t(
              'xpbx.settings.queue-actions.notification.create_timeout_actions'
            )
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!--Name -->
      <FormInput
        id="name"
        :error="errors?.name?.[0]"
        v-model:modelValue="record.name"
        :label="$t('xpbx.settings.queue-actions.labels.name')"
        :hint="$t('xpbx.settings.queue-actions.hints.name')"
      />
      <!-- Action -->
      <div class="flex gap-6">
        <div>
          <FormDropdown
            optionLabel="name"
            :options="fileTypeQueueOptions"
            v-model:modelValue="record.action"
            :error="errors?.action?.[0]"
            :placeholder="
              $t('xpbx.settings.queue-actions.placeholders.select_filetype')
            "
            :label="$t('xpbx.settings.queue-actions.labels.action')"
            :hint="$t('xpbx.settings.queue-actions.hints.action')"
          />
        </div>

        <!-- Value -->
        <div>
          <FormDropdown
            optionLabel="name"
            :options="soundFileOptions"
            v-model:modelValue="record.value"
            :error="errors?.value?.[0]"
            :placeholder="
              $t('xpbx.settings.queue-actions.placeholders.select_file')
            "
            :label="$t('xpbx.settings.queue-actions.labels.value')"
            :hint="$t('xpbx.settings.queue-actions.hints.value')"
          />
        </div>
      </div>

      <!-- Timerange -->
      <div class="field mb-1">
        <div class="">
          <label for="timerange">{{
            $t("xpbx.settings.queue-actions.labels.timerange-start")
          }}</label>
          <div class="flex gap-2 items-center mb-1">
            <div class="w-36 flex items-end gap-1">
              <InputNumber
                v-model="record.timerange"
                inputId="minmax-buttons"
                showButtons
                :min="0"
                :max="100"
              />
              <span> (s)</span>
            </div>
            <span>-</span>
            <div class="w-36 flex items-end gap-1">
              <InputNumber
                v-model="record.secondTimerange"
                inputId="minmax-buttons"
                showButtons
                :min="0"
                :max="100"
              />
              <span> (s)</span>
            </div>
          </div>
          <small class="p-error" v-if="!!errors?.timerange?.[0]">{{
            errors?.timerange?.[0]
          }}</small>
          <QueueHint
            :title="$t('xpbx.settings.queue-actions.hints.timerange_1')"
          />
        </div>
      </div>

      <!-- Is active -->
      <InputSwitch
        v-model:modelValue="record.is_active"
        :label="$t('xpbx.settings.queue-actions.labels.active')"
      />

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="createDialog = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed, inject } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import validator from "@/composables/auth/validator";
import useQueues from "@/modules/xpbx/composables/useQueues";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import Dropdown from "primevue/dropdown";
import Tooltip from "primevue/tooltip";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import useSoundFiles from "@/modules/xpbx/composables/useSoundFiles";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import AudioPlayer from "@/modules/xpbx/pages/settings/sound-files/AudioPlayer.vue";
import { validateCreatedQueueAction } from "@/composables/auth/queueValidatuions";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "QueueActions",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    FormInput,
    Dropdown,
    FormDropdown,
    InputSwitch,
    InputNumber,
    QueueHint,
    TableActions,
    AudioPlayer,
    DialogButtons,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    data: {
      type: Object || Array,
      required: true,
    },
  },

  setup(props) {
    const t = inject("t");
    const route = useRoute();
    const record = ref({});
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const selectText = t("xpbx.settings.dropdown.select_option");

    const { updateQueueOption, createQueueOption, deleteQueueOption } =
      useQueues();
    const { fileTypeQueueOptions, actionOptions, getOptionValue } =
      useQueueOptions();
    const { findSoundFIles, soundFiles } = useSoundFiles();

    const soundFileOptions = computed(() => {
      if (record.value.action?.value || record.value.action) {
        const fileType = record.value.action.value || record.value.action;
        const filteredType = fileType === "playmoh" ? "moh" : "ivr";

        const filteredFiles = soundFiles.value.filter(
          (file) => file.filetype === filteredType
        );

        return filteredFiles.map((file) => ({
          name: file.description,
          value: file.id,
          code: file.id,
        }));
      }

      return soundFiles.value.map((file) => ({
        name: file.description,
        value: file.id,
        code: file.id,
      }));
    });

    const setDefaultRecord = () => {
      record.value = {
        name: "",
        action: {
          name: selectText,
          value: null,
        },
        timerange: 0,
        secondTimerange: 0,
        value: {
          name: selectText,
          value: null,
        },
        is_active: false,
      };
    };

    // Data table functions
    const openNew = () => {
      submitted.value = false;
      setDefaultRecord();
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      clearErrors();
      setDefaultRecord();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;

      if (selectedRecord.value.id) {
        await deleteQueueOption(
          selectedRecord.value,
          id,
          selectedRecord.value.id,
          "actions"
        );
      }
      deleteDialog.value = false;
    };

    const editHandle = (data) => {
      isEdit.value = true;
      selectedRecord.value = data;
      const selectedFile = soundFiles.value.find(
        (file) => file.id === data.announcement_id
      );

      const selectedFiletype = fileTypeQueueOptions.find(
        (i) => i.value === data.action
      );

      const valueData = {
        name: selectedFile.description,
        value: selectedFile.id,
        code: selectedFile.id,
      };

      const filetypeData = {
        name: selectedFiletype.name,
        value: selectedFiletype.value,
      };

      const form = {
        ...data,
        value: valueData,
        action: filetypeData,
        timerange: data.timerange?.[0] || "",
        secondTimerange: data.timerange?.[1] || "",
        is_active: data.is_active === 1 ? true : false,
      };

      record.value = form;
      selectedRecord.value = data;
      createDialog.value = true;
    };

    const updateRecord = async (formData) => {
      const id = route.params.id;

      if (formData.id) {
        await updateQueueOption(formData, id, formData.id, "actions");
      }
      createDialog.value = false;
    };

    const createRecord = async (formData) => {
      const id = route.params.id;
      await createQueueOption(formData, id, "actions");
      createDialog.value = false;
    };

    const create = async () => {
      const data = record.value;
      const isValid = validateCreatedQueueAction(data);

      if (!isValid) return;

      const timerange = [+data.timerange || 0, +data.secondTimerange || 0];
      const formData = {
        name: data.name,
        announcement_id: data.value?.code || data.value,
        is_active: data.is_active ? 1 : 0,
        value: getOptionValue(data.value),
        action: getOptionValue(data.action),
        timerange,
      };

      submitted.value = true;
      if (isEdit.value) {
        formData.id = record.value.id;
        await updateRecord(formData);
      } else {
        await createRecord(formData);
      }
    };

    const getFileType = (value) => {
      const type = t(`xpbx.settings.queue-actions.options.${value}`);

      return type || value;
    };

    const setActive = async (data) => {
      const id = route.params.id;
      const formData = {
        ...data,
        is_active: data.is_active === 1 ? 0 : 1,
      };

      await updateQueueOption(formData, id, data.id, "actions");
    };

    const getFilename = (id) => {
      const file = soundFiles.value.find((file) => file.id === id);
      return file?.filename || "";
    };

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findSoundFIles();
    });

    return {
      record,
      isEdit,
      errors,
      submitted,
      soundFiles,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      selectedRecords,
      actionOptions,
      fileTypeQueueOptions,
      soundFileOptions,
      // Data table functions
      reset,
      create,
      openNew,
      setActive,
      getFileType,
      getFilename,
      editHandle,
      closeDialog,
      findSoundFIles,
      createRecord,
      updateRecord,
      setDefaultRecord,
      createQueueOption,
      updateQueueOption,
      deleteQueueOption,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>