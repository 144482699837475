<template>
  <div>
    <div
      class="card relative table-wrapper"
      :class="{ 'table-none': !data?.length }"
    >
      <DataTable
        ref="dt"
        scrollable
        :value="data"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        selectionMode="single"
        v-model:selection="selectedRecords"
      >
        <template #empty>
          {{ $t("xpbx.settings.queue-members.fields.empty_table") }}</template
        >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                class="mr-2 add-record-button"
                @click="openNew"
                :label="$t('xpbx.settings.queue-members.buttons.new')"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column
          sortable
          style="width: 25%"
          field="workplace"
          :header="$t('xpbx.settings.queue-members.fields.workplace')"
        >
          <template #body="{ data }">
            {{ data.workplace }}
            <TableActions
              :data="data"
              :id="data.id"
              @edit="editHandle"
              :showIsActive="false"
              :isActive="data.is_active"
              @delete="deleteSelectedRecord"
            />
          </template>
        </Column>
        <Column
          sortable
          style="width: 25%"
          field="user_id"
          :header="$t('xpbx.settings.queue-members.fields.user')"
        >
          <template #body="{ data }">
            {{ findUsername(data.user_id) }}
          </template>
        </Column>
        <Column
          sortable
          style="width: 25%"
          field="skill_level"
          :header="$t('xpbx.settings.queue-members.fields.skill_level')"
        ></Column>
        <Column sortable style="width: 25%" field="calls" header="Calls" />
        <Column
          sortable
          style="width: 25%"
          field="auto_answer"
          :header="$t('xpbx.settings.queue-members.fields.auto_answer')"
        ></Column>
      </DataTable>

      <!-- <Loader v-else /> -->
      <p class="text-center my-2" v-if="!data?.length">
        {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}
      </p>
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.id">
          {{
            $t("xpbx.settings.queue-members.notification.confirm_delete", {
              delete: `member ${selectedRecord?.id}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          text
          @click="deleteDialog = false"
          :label="$t('xpbx.settings.queue-members.buttons.no')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.settings.queue-members.buttons.yes')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.queue-members.edit_queue_member')
          : $t('xpbx.settings.queue-members.add_queue_member')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!-- WorkPlace -->
      <FormInput
        id="name"
        :error="errors?.workplace?.[0]"
        v-model:modelValue="record.workplace"
        :label="$t('xpbx.settings.queue-members.labels.workplace')"
        :hint="$t('xpbx.settings.queue-members.hints.workplace')"
      />

      <!-- Users -->
      <FormDropdown
        optionLabel="name"
        :options="usersData"
        v-model:modelValue="record.user_id"
        :label="$t('xpbx.settings.queue-members.labels.username')"
        :hint="$t('xpbx.settings.queue-members.hints.username')"
      />

      <FormDropdown
        :options="wieghtOptionsV2"
        :error="errors?.auto_answer?.[0]"
        v-model:modelValue="record.auto_answer"
        :label="$t('xpbx.settings.queue-members.labels.auto_answer')"
        :hint="$t('xpbx.settings.queue-members.hints.auto_answer')"
      />

      <FormDropdown
        :options="skills"
        :error="errors?.skill_level?.[0]"
        v-model:modelValue="record.skill_level"
        :label="$t('xpbx.settings.queue-members.labels.skill_level')"
        :hint="$t('xpbx.settings.queue-members.hints.skill_level')"
      />

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="createDialog = false"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed, inject } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import validator from "@/composables/auth/validator";
import useUsers from "@/modules/xpbx/composables/useUsers";
import useQueues from "@/modules/xpbx/composables/useQueues";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import {
  validateUpdateQueueMember,
  validateCreatedQueueMember,
} from "@/composables/auth/queueValidatuions";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "QueueMembers",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    DialogButtons,
    FormInput,
    TableActions,
    FormDropdown,
  },

  props: {
    data: {
      type: Object || Array,
      required: true,
    },
  },

  setup() {
    const t = inject("t");
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const record = ref({});
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const selectText = t("xpbx.settings.dropdown.select_option");

    const { wieghtOptionsV2, getOptionValue } = useQueueOptions();
    const { updateQueueOption, createQueueOption, deleteQueueOption } =
      useQueues();

    const { findUsers, users } = useUsers();

    const usersData = computed(() => {
      if (!users.value) return [];

      return users.value.map((user) => {
        return {
          name: user.description,
          value: user.id,
        };
      });
    });

    const setDefaultRecord = () => {
      record.value = {
        skill_level: selectText,
        auto_answer: selectText,
        workplace: "",
        user_id: {
          name: selectText,
          value: null,
        },
      };
    };

    // Data table functions
    const openNew = () => {
      setDefaultRecord();
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      setDefaultRecord();
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;

      if (selectedRecord.value.id) {
        await deleteQueueOption(
          selectedRecord.value,
          id,
          selectedRecord.value.id,
          "members"
        );
      }
      deleteDialog.value = false;
    };

    const editHandle = (data) => {
      const userId = usersData.value.find(
        (user) => user.value === data.user_id
      );

      record.value = {
        ...data,
        auto_answer: data.auto_answer ? data.auto_answer.toString() : "-1",
        skill_level: data.skill_level ? data.skill_level.toString() : "1",
        user_id: userId || {
          name: selectText,
          value: null,
        },
      };
      isEdit.value = true;
      createDialog.value = true;
    };

    const updateRecord = async (data) => {
      const isValid = validateCreatedQueueMember(data);

      if (!isValid) return;

      const id = route.params.id;
      const userId = getOptionValue(data.user_id);

      const formData = {
        id: data.id,
        workplace: data.workplace,
        auto_answer: getOptionValue(data.auto_answer),
        skill_level: getOptionValue(data.skill_level),
        user_id: userId,
      };

      if (formData.id) {
        await updateQueueOption(formData, id, formData.id, "members");
      }
      createDialog.value = false;
    };

    const createRecord = async (data) => {
      const isValid = validateCreatedQueueMember(data);

      if (isValid) {
        const id = route.params.id;
        const userId = getOptionValue(data.user_id);

        const formData = {
          workplace: data.workplace,
          auto_answer: getOptionValue(data.auto_answer),
          skill_level: getOptionValue(data.skill_level),
        };

        if (userId) formData.user_id = userId;

        await createQueueOption(formData, id, "members");
        createDialog.value = false;
      }
    };

    const create = async () => {
      submitted.value = true;
      if (isEdit.value) {
        await updateRecord(record.value);
      } else {
        await createRecord(record.value);
      }
    };

    const findUsername = (id) => {
      const user = users.value.find((user) => user.id === id);
      return user?.description || user?.name || "";
    };

    const skills = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findUsers();
    });

    return {
      users,
      skills,
      record,
      isEdit,
      errors,
      usersData,
      submitted,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      selectedRecords,
      wieghtOptionsV2,
      // Data table functions
      reset,
      create,
      openNew,
      editHandle,
      closeDialog,
      findUsername,
      createRecord,
      updateRecord,
      setDefaultRecord,
      createQueueOption,
      updateQueueOption,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>
